<template>
	<div>
		<el-dialog title="付款记录" :close-on-click-modal="false" :visible.sync="visible"
			width="1400px">
			<el-row v-if="showAdd">
			  <el-col :span="12">
				  <el-button v-preventReClick class="btn" type="primary" size="small" @click="addOrUpdateHandle()">添加</el-button>
			  </el-col>
			</el-row>
			<el-table
			  class="dataListTable"
			  :data="dataList"
			  header-align="center"
			  style="width: 100%; margin-top: 20px;"
			  header-cell-class-name="stepPointTHeader">
			 <el-table-column type="index" width="60" align="center">
			 </el-table-column>
			 <el-table-column prop="collectionItem" label="付款项目" align="left" header-align="center">
			 </el-table-column>
			<el-table-column prop="billDate" label="付款日期 " width="150" align="center">
			</el-table-column>
			 <el-table-column prop="billAmount" label="付款金额" width="110" align="center">
			 </el-table-column>
			 <el-table-column prop="billNo" label="发票号 " width="150" align="center">
			 </el-table-column>
			<el-table-column prop="remark" label="备注 " align="center">
			</el-table-column>
<!--			 <el-table-column prop="billFlag" label="开票标识" width="100" align="center">-->
<!--				<template slot-scope="scope">-->
<!--					<span v-if="scope.row.billFlag == 1">已开票</span>-->
<!--					<span v-if="scope.row.billFlag == 0">未开票</span>-->
<!--				</template>-->
<!--			 </el-table-column>-->
<!--			 <el-table-column prop="accountAmount" label="到账金额" width="150" align="center">-->
<!--			 </el-table-column>-->
<!--			 <el-table-column prop="accountDate" label="到账时间" width="150" align="center">-->
<!--			 </el-table-column>-->
<!--			 <el-table-column prop="accountFlag" label="到账标识" width="150" align="center">-->
<!--				 <template slot-scope="scope">-->
<!--				 	<span v-if="scope.row.billFlag == 1">已到账</span>-->
<!--				 	<span v-if="scope.row.billFlag == 0">未到账</span>-->
<!--				 </template>-->
<!--			 </el-table-column>-->
			 <el-table-column
				  label="操作"
				  width="150"
				  v-if="showEdit || showDelete"
				  align="center">
			  <template slot-scope="scope">
				  <el-button v-preventReClick type="danger" size="small" @click="addOrUpdateHandle(scope.row.recNo)" v-if="showEdit">修改</el-button>
				  <el-button v-preventReClick type="success" size="small" @click="deleteHandle(scope.row.recNo,scope.row.collectionItem)" v-if="showDelete">删除</el-button>
				  <!-- <div class="listSelFileBtn" @click="selectFile(scope.row.changeFileUri)" v-if="scope.row.showFileFlag == 1">
					  <i class="el-icon-paperclip"></i>
					  查看文件
				  </div> -->
			  </template>
			 </el-table-column>
			</el-table>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">返回</el-button>
			</el-row>
		</el-dialog>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getChangedLogList()"></add-or-update>
	</div>
</template>

<script>
	import AddOrUpdate from './payment-add-or-update'
	import $common from "@/utils/common.js"
	export default {
		name: "ownerContract-payment-log",
		data() {
			return {
				showSearch: false,
				showAdd: false,
				showEdit: false,
				showDelete: false,
				visible: false,
				inline: false,
				contractId:"",
				dataList:[],
				addOrUpdateVisible: false
			}
		},
		components: {
		    AddOrUpdate
		},
		methods: {
			init(id) {
				this.showSearch = $common.isAuth('ownerContract:payment:search');
				this.showAdd = $common.isAuth('ownerContract:payment:add');
				this.showEdit = $common.isAuth('ownerContract:payment:edit');
				this.showDelete = $common.isAuth('ownerContract:payment:delete');
				this.contractId = id?id:0;
				if(id && this.showSearch){
					this.getChangedLogList();
				}
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			getChangedLogList(){
				this.$http({
				  url:  this.$store.state.httpUrl + "/business/ownercontractcollection/list",
				  method: "get",
				  params: {
					contractId: this.contractId
				  },
				  }).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body;
					}
				});
			},
			selectFile(url){
				window.open(url);
			},
			// 新增检验项目
			addOrUpdateHandle (id){
			    this.addOrUpdateVisible = true
			    this.$nextTick(() => {
			        this.$refs.addOrUpdate.init(id,this.contractId)
			    })
			},
			deleteHandle (id,collectionItem) {
				this.$confirm(`您确定要对选择付款记录进行删除操作?`, '提示', {
			        confirmButtonText: '确定',
			        cancelButtonText: '取消',
			        type: 'warning'
			    }).then(() => {
			        this.$http({
						url:  this.$store.state.httpUrl + "/business/ownercontractchange/delete",
			            method: 'post',
			            data: [id],
			        }).then(({data}) => {
			            if (data && data.resultCode === 200) {
			                this.$message({
			                    message: '操作成功',
			                    type: 'success',
			                    duration: 1500,
			                    onClose: () => {
			                        this.getChangedLogList()
			                    }
			                })
			            } else {
			                this.$message.error(data.msg)
			            }
			        })
			    })
			},
		}
	}
</script>
<style lang="scss"  scoped>
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
</style>
