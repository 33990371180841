<template>
	<el-dialog :title="!dataForm.recNo ? '新增付款记录' : '修改付款记录'" :close-on-click-modal="false" :visible.sync="visible"
		width="1000px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row>
				<el-col :span="12">
					<el-form-item label="付款项目" prop="collectionItem">
						<el-input class="selItemInput" v-model="dataForm.collectionItem" placeholder="请输入" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="付款日期" prop="billDate">
						<el-date-picker  class="selItemInput"
						  v-model="dataForm.billDate"
						  type="date"
						  value-format="yyyy-MM-dd"
						  placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="发票号" prop="billNo">
						<el-input class="selItemInput" v-model="dataForm.billNo" placeholder="请输入" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
<!--				<el-col :span="12">-->
<!--					<el-form-item label="是否开票" prop="billFlag">-->
<!--						<el-radio-group v-model="dataForm.billFlag">-->
<!--							<el-radio label="1">已开票</el-radio>-->
<!--							<el-radio label="0">未开票</el-radio>-->
<!--						</el-radio-group>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="12">-->
<!--					<el-form-item label="是否到账" prop="accountFlag">-->
<!--						<el-radio-group v-model="dataForm.accountFlag">-->
<!--							<el-radio label="1">已到账</el-radio>-->
<!--							<el-radio label="0">未到账</el-radio>-->
<!--						</el-radio-group>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
					<el-col :span="12">
						<el-form-item label="付款金额" prop="billAmount">
							<el-input class="selItemInput" v-model="dataForm.billAmount" placeholder="请输入" style="width:300px"></el-input>
						</el-form-item>
					</el-col>
<!--				<el-col :span="12">-->
<!--					<el-form-item label="付款金额" prop="accountAmount">-->
<!--						<el-input class="selItemInput" v-model="dataForm.accountAmount" placeholder="请输入" style="width:300px"></el-input>-->
<!--					</el-form-item>-->
<!--				</el-col>-->
<!--				<el-col :span="12">-->
<!--					<el-form-item label="到账时间" prop="accountDate">-->
<!--						<el-date-picker  class="selItemInput"-->
<!--						  v-model="dataForm.accountDate"-->
<!--						  type="date"-->
<!--						  value-format="yyyy-MM-dd"-->
<!--						  placeholder="选择日期">-->
<!--						</el-date-picker>-->
<!--					</el-form-item>-->
<!--				</el-col>-->

				<!-- <el-col :span="24">
					<el-form-item label="扫描件">
						<div class="addFileBox" v-for="(item,index) in fileList">
						    <div>
								<span style="display: inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: top;">
									{{item.fileName}}
								</span>
								<i class="el-icon-delete" @click="delFile(index)"></i>
							</div>
						</div>
						<el-upload
						  class="addFileBox"
						  ref="uploadFile"
						  :action="action"
						  v-if="!fileList || fileList.length < 1"
						  :http-request="uploadFile">
						  <el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder-add" style="padding: 9px 0 !important;">添加文件</el-button>
						</el-upload>
					</el-form-item>
				</el-col> -->
				<el-col :span="24">
					<el-form-item label="备注" prop="remark">
						<el-input class="selItemInput descAreaLg" type="textarea" rows="3" v-model="dataForm.remark" placeholder="请填写"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "log-add-or-update",
		data() {
			return {
				visible: false,
				inline: false,
				action: "",
				fileList:[],
				dataForm: {
					contractId:"",
					recNo:"",
					accountAmount:"",
					accountDate:"",
					accountFlag:"",
					billAmount:"",
					billDate:"",
					billFlag:"",
					billNo:"",
					collectionItem:"",
					remark:""
				},
				dataRule: {

				},
			}
		},
		methods: {
			init(id,contractId) {
				this.fileList=[];
				this.dataForm = {
					contractId:contractId,
					recNo:"",
					accountAmount:"",
					accountDate:"",
					accountFlag:"",
					billAmount:"",
					billDate:"",
					billFlag:"",
					billNo:"",
					collectionItem:"",
					remark:""
				};
				this.dataForm.recNo = id?id:0;
				if(id){
					this.getDetail();
				}
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			getDetail(){
				this.$http({
				  url: this.$store.state.httpUrl + "/business/ownercontractcollection/info/"+this.dataForm.recNo,
				  method: "get",
				  params: {},
				}).then(({ data }) => {
				  if (data && data.resultCode === 200) {
					this.dataForm = data.body
				  }
				});
			},
			delFile(index){
				this.dataForm.changeFilePath = "";
				this.fileList = [];
			},
			uploadFile(param){
				const formData = new FormData();
				formData.append('file', param.file);
				this.$refs.uploadFile.clearFiles();
				this.$http.post(
					this.$store.state.httpUrl + "/file/upload/"+$common.getItem("workNo")+"/97/0",
					formData,
					{
					  headers: { 'Content-Type': 'multipart/form-data' }
					},
				).then(res => {
				  res = res.data
				  if (res.resultCode == 200){
					this.dataForm.changeFilePath = res.body.fileSavePath;
					this.fileList = [{
						fileName: res.body.fileName,
						filePath: res.body.fileSavePath,
						fileUri: res.body.fileUri
					}];
				  } else {
					this.$message.info('文件上传失败')
				  }
				})
			},
			dataFormSubmit() {
			  this.$refs["dataForm"].validate(valid => {
				if (valid) {
				  let _url = "/business/ownercontractcollection/save";
				  if(this.dataForm.recNo && this.dataForm.recNo != 0){
					_url = "/business/ownercontractcollection/update";
				  }
				  this.$http({
					url: this.$store.state.httpUrl + _url,
					method: "post",
					data: this.$http.adornData(this.dataForm)
				  }).then(({ data }) => {
					if (data && data.resultCode === 200) {
					  this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
						  this.visible = false;
						  this.$emit("refreshDataList");
						}
					  });
					} else {
					  this.$message.error(data.msg);
					}
				  });
				}
			  });
			}
		}
	}
</script>
<style lang="scss"  scoped>
	.selItemInput.descAreaLg{
		width: 718px !important;
	}
</style>
